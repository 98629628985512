/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState } from "react";
// import menu from "../../config/menu.json";
import { Link } from "@remix-run/react";
import LanguageSelector from "../LanguageSelector";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

export default function Header({
  title,
  // signInLabel,
  // getStartedLabel,
  // dashboardLabel,
  locale,
}: Readonly<{
  title: string;
  signInLabel: string;
  getStartedLabel: string;
  dashboardLabel: string;
  locale?: string;
}>) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Opacity transition on scroll
    const header = document.getElementById("navigation");
    const handleScroll = () => {
      if (header) {
        if (window.scrollY > 100) {
          header.classList.remove("bg-opacity-0");
          header.classList.add("bg-opacity-90");
          header.classList.add("shadow-md");
        } else {
          header.classList.add("bg-opacity-0");
          header.classList.remove("bg-opacity-90");
          header.classList.remove("shadow-md");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return (
    <header
      id="navigation"
      className="fixed inset-x-0 top-0 z-50 bg-white bg-opacity-0"
    >
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <Link to={`/${locale}`} className="-m-1.5 p-1.5">
            <span className="sr-only">{title}</span>
            <img
              alt=""
              src="/images/logo-remarc.svg"
              className="h-[40px] w-[133px]"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        {/* <div className="hidden lg:flex lg:gap-x-12">
          {menu.main.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm leading-6 text-gray-900 hover:text-gray-500"
            >
              {item.name}
            </Link>
          ))}
        </div> */}
        <div className="hidden items-center space-x-6 lg:flex lg:flex-1 lg:justify-end">
          <div>
            <LanguageSelector />
          </div>
          {/* <SignedIn>
            <div>
              <Link to="/app">{dashboardLabel}</Link>
            </div>
            <div>
              <UserButton />
            </div>
            <div>
              <SignOutButton />
            </div>
          </SignedIn>
          <SignedOut>
            <Link
              to="/sign-in"
              className="w-16 text-sm font-semibold leading-6 text-gray-900 hover:animate-pulse hover:opacity-90"
            >
              {signInLabel}
            </Link>
            <Link to="/sign-up" className="relative w-40 p-[3px]">
              <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500" />
              <div className="group relative rounded-[6px] bg-white px-8 py-2 text-center text-indigo-800 transition duration-200 hover:bg-transparent hover:text-white dark:bg-black dark:text-white dark:hover:bg-transparent">
                {getStartedLabel}
              </div>
            </Link>
          </SignedOut> */}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={`/${locale}`} className="-m-1.5 p-1.5">
              <span className="sr-only">{title}</span>
              <img
                alt="Remarc"
                src="/images/logo-remarc.svg"
                className="h-8 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              {/* <div className="space-y-2 py-6">
                {menu.main.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div> */}
              <div className="py-6">
                <LanguageSelector />
              </div>
              {/* <SignedIn>
                <div>
                  <Link to="/app">{dashboardLabel}</Link>
                </div>
                <div>
                  <UserButton />
                </div>
                <div>
                  <SignOutButton />
                </div>
              </SignedIn>
              <SignedOut>
                <div className="py-4">
                  <Link
                    to="/sign-in"
                    className="text-sm font-semibold leading-6 text-gray-900 hover:animate-pulse hover:opacity-90"
                  >
                    {signInLabel}
                  </Link>
                </div>
                <div className="relative py-4">
                  <Link to="/sign-up" className="relative p-[3px]">
                    <div className="group relative rounded-[6px] bg-gradient-to-r from-cyan-500 to-blue-500 px-8 py-2 text-center text-white transition duration-200 dark:bg-black dark:text-white dark:hover:bg-transparent">
                      {getStartedLabel}
                    </div>
                  </Link>
                </div>
              </SignedOut> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}

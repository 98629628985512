import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { useTina } from "tinacms/dist/react";
import {
  json,
  redirect,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { client } from "tina/__generated__/client";
import { z } from "zod";

import { WaitListApi } from "~/services/waitlist.server";

import i18nServer, { localeCookie } from "~/modules/i18n.server";
import { getLang } from "~/lib/utils";

import Header from "~/components/Landing/Header";
import Hero from "~/components/Landing/Hero";
import Challenge from "~/components/Landing/Challenge";
import BottomCTA from "~/components/Landing/BottomCTA";
import WaitList from "~/components/Landing/WaitList";

import { useEffect, useState } from "react";
import { NotificationService } from "~/services/notification.server";

type MetaData = {
  data: {
    page: {
      title: string;
    };
  };
};

export const meta: MetaFunction = ({ data }) => {
  const metaData = data as MetaData;

  return [
    { title: metaData.data.page.title },
    { name: "description", content: metaData.data.page.title },
  ];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  const locale = await i18nServer.getLocale(request);
  const lang = getLang(params);
  console.log({ lang, locale });

  if (lang && lang !== locale) {
    console.log("Change language home");
    return redirect(lang === "en" ? "/" : `/${lang}`, {
      headers: {
        "Cache-Control": "max-age=0",
        "Set-Cookie": await localeCookie.serialize(lang),
      },
    });
  }

  const { data, query, variables } = await client.queries.page({
    relativePath: `${lang}/home.md`,
  });

  console.log({ data, blocks: data.page.blocks?.[0] });

  return json(
    { data, query, variables, locale },
    {
      headers: {
        "Set-Cookie": await localeCookie.serialize(lang),
      },
    },
  );
}

export const schema = z.object({
  email: z.string().email(),
  language: z.string().optional(),
});

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const language = formData.get("language");

  try {
    const validation = schema.parse({ email, language });
    if (!email) {
      throw new Error("Email is required");
    }

    await WaitListApi.join(validation.email, validation.language ?? "en"); // Assuming waitlist is an async function

    await NotificationService.slackAnnouncement({
      title: "New Waitlist Signup",
      description: `New user signed up for the waitlist with email: ${email}`,
    });

    return json({ success: true, errors: null });
  } catch (error) {
    if (error instanceof z.ZodError) {
      return json({ errors: error.errors, success: false });
    }

    return json({
      errors: [
        { message: "There was an error with the submission please try later." },
      ],
      success: false,
    });
  }
};

export default function Index() {
  const navigation = useNavigation();
  const { data, variables, query, locale } = useLoaderData<typeof loader>();
  const isLoading = ["loading", "submitting"].includes(navigation.state);
  const tina = useTina({ query, variables, data });
  const actionData = useActionData<typeof action>();
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (actionData?.success && !submitted) {
      setSubmitted(true);
    }
  }, [actionData, submitted]);

  return (
    <div className="bg-white">
      {/* Header */}
      <Header
        title={tina.data.page.title ?? ""}
        signInLabel={t("sign_in")}
        getStartedLabel={t("get_started")}
        dashboardLabel={t("dashboard")}
        locale={locale === "en" ? "" : locale}
      />

      <main className="isolate">
        {/* Hero section */}

        {tina.data.page.blocks?.map((block) => {
          switch (block?.__typename) {
            case "PageBlocksHero":
              return <Hero key={block.__typename} {...block} />;
            case "PageBlocksChallenge":
              return <Challenge key={block.__typename} {...block} />;
            case "PageBlocksBottom_cta":
              return <BottomCTA key={block.__typename} {...block} />;
            case "PageBlocksWaitlist":
              return (
                <WaitList
                  rest={block}
                  key={block.__typename}
                  submitted={submitted}
                  errors={actionData?.errors}
                  language={locale}
                  isLoading={isLoading}
                  message={block.message ?? "Get Early Access"}
                  subMessage={
                    block.sub_message ??
                    "Be the first to experience our new service. Sign up now and stay informed about our launch!"
                  }
                  buttonLabel={block.cta_label ?? "Join"}
                  successMessage={
                    block.success_message ??
                    "Thank you for signing up! We’re thrilled to have you on board and can't wait to share Remarc with you soon."
                  }
                  helperText={block.help_text ?? "We don't spam. Promise."}
                  buttonWait={block.cta_wait ?? "Sending..."}
                />
              );
          }
        })}

        {/* Feature section */}

        {/* Testimonial section */}
        {/* <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
              className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
            />
            <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
            <div
              aria-hidden="true"
              className="absolute -left-80 -top-56 transform-gpu blur-3xl"
            >
              <div
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-[0.45]"
              />
            </div>
            <div
              aria-hidden="true"
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
            >
              <div
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
              />
            </div>
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              <img
                alt=""
                src="https://tailwindui.com/img/logos/workcation-logo-white.svg"
                className="h-12 w-auto"
              />
              <figure>
                <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                  <p>
                    “Amet amet eget scelerisque tellus sit neque faucibus non
                    eleifend. Integer eu praesent at a. Ornare arcu gravida
                    natoque erat et cursus tortor consequat at. Vulputate
                    gravida sociis enim nullam ultricies habitant malesuada
                    lorem ac.”
                  </p>
                </blockquote>
                <figcaption className="mt-6 text-base text-white">
                  <div className="font-semibold">Judith Black</div>
                  <div className="mt-1">CEO of Tuple</div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div> */}

        {/* Pricing section */}
        {/* <div className="py-24 sm:pt-48">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-blue-600">
                Pricing
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Pricing plans for teams of&nbsp;all&nbsp;sizes
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
              quasi iusto modi velit ut non voluptas in. Explicabo id ut
              laborum.
            </p>
            <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={clsx(
                    tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                    tierIdx === 0 ? "lg:rounded-r-none" : "",
                    tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                    "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10",
                  )}
                >
                  <div>
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id={tier.id}
                        className={clsx(
                          tier.mostPopular ? "text-blue-600" : "text-gray-900",
                          "text-lg font-semibold leading-8",
                        )}
                      >
                        {tier.name}
                      </h3>
                      {tier.mostPopular ? (
                        <p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                          Most popular
                        </p>
                      ) : null}
                    </div>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /month
                      </span>
                    </p>
                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            aria-hidden="true"
                            className="h-6 w-5 flex-none text-blue-600"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={clsx(
                      tier.mostPopular
                        ? "bg-blue-600 text-white shadow-sm hover:bg-blue-500"
                        : "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                      "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600",
                    )}
                  >
                    Buy plan
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* FAQs */}
        {/* <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div> */}

        {/* CTA section */}
      </main>

      {/* Footer */}
      <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
        <footer
          aria-labelledby="footer-heading"
          className="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
        >
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <img
              alt={data.page.title ?? "Remarc logo"}
              src="/images/logo-remarc.svg"
              className="h-7"
            />
            <p className="text-center">
              <span className="block text-sm leading-6 text-gray-600">
                &copy; {new Date().getFullYear()} Remarc. All rights reserved.
              </span>
              <span className="mt-1 block text-sm leading-6 text-gray-600">
                Contact us at{" "}
                <a
                  href="mailto:hello@remarc.ai"
                  className="text-blue-600 hover:text-blue-500"
                >
                  hello@remarc.ai
                </a>
              </span>
            </p>
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="justify-items-start md:grid md:grid-cols-2 md:gap-8">
                {/* <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Solutions
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Support
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Company
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.company.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
                {/* <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Legal
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.legal.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
